import React from 'react';
import _copy from '../../copy/allProducts';
import useCopy from '../../hooks/useCopy';
import ContentContainer from '../../components/ContentContainer';
import { Grid, GridItem, Heading, Text } from '@chakra-ui/react';
import Link from '../../components/Link';

interface ProductSectionProps {
  title: string;
  items: { title: string; text: string; link: string }[];
}

const ProductSection = ({ title, items }: ProductSectionProps) => {
  return (
    <GridItem>
      <Heading
        as='h2'
        color='apryseNavy'
        fontSize='24px'
        lineHeight='32px'
        fontWeight={400}
        mb={6}
      >
        {title}
      </Heading>
      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          smd: 'repeat(2, 1fr)',
          lg: 'repeat(3, 1fr)',
        }}
        gap={6}
      >
        {items.map((item, index) => (
          <GridItem key={index} w='100%'>
            <Link to={item.link}>
              <Text
                fontWeight={500}
                fontSize='16px'
                lineHeight='24px'
                color='apryseIndigo'
                mb={2}
              >
                {item.title}
              </Text>
            </Link>
            <Text
              color='apryseNavy'
              fontWeight={400}
              lineHeight='24px'
              maxW='373px'
            >
              {item.text}
            </Text>
          </GridItem>
        ))}
      </Grid>
    </GridItem>
  );
};

const AllProducts = () => {
  const copy = useCopy(_copy);

  return (
    <ContentContainer>
      <Grid templateColumns='repeat(1, 1fr)' gap={10}>
        {copy.map((section, index) => (
          <ProductSection
            key={index}
            title={section.title}
            items={section.items}
          />
        ))}
      </Grid>
    </ContentContainer>
  );
};

export default AllProducts;
